import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../../pages/login";
import DashboardPage from "../../pages/dashboard";
import SettingsPage from "../../pages/settings";
import routes from "../../config/routes";
import DiscoverPage from "../../pages/discover";
import { ProtectedRoute } from "../protected-route";
import RecordingsNew from "../../pages/recordings/new";
import AudioStreamer from "../../pages/stream";
import RegisterPage from "../../pages/register";
import RecordingsDetailPage from "../../pages/recordings/detail";
import CollectionsNewPage from "../../pages/collections/new";
import CollectionsDetailPage from "../../pages/collections/detail";
import HomePage from "../../pages/home";
import PricingPage from "../../pages/pricing";
import LayoutStandard from "../layouts/standard";
import AdvancedStream from "../../pages/stream-advanced";
import DocumentationPage from "../../pages/documentation";
import RecordingsListenPage from "../../pages/recordings/listen";
import StreamsListenPage from "../../pages/streams/listen";

export const router = createBrowserRouter([
  // public routes
  {
    path: routes.home,
    element: (
      <LayoutStandard>
        <HomePage />
      </LayoutStandard>
    )
  },
  {
    path: routes.login,
    element: (
      <LayoutStandard>
        <LoginPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.register,
    element: (
      <LayoutStandard>
        <RegisterPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.pricing,
    element: (
      <LayoutStandard>
        <PricingPage />
      </LayoutStandard>
    )
  },
  {
    path: routes.discover,
    element: (
      <LayoutStandard>
        <DiscoverPage />
      </LayoutStandard>
    )
  },

  // private routes
  {
    path: routes.dashboard,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <DashboardPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },
  // recordings
  {
    path: "/recordings/new",
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <RecordingsNew />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },
  {
    path: "/recordings/:id",
    element: (
      <LayoutStandard>
        <RecordingsListenPage />
      </LayoutStandard>
    )
  },
  {
    path: "/recordings/:id/detail",
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <RecordingsDetailPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },

  // manage stream
  {
    path: "/manage-stream",
    element: (
      <LayoutStandard>
        <AudioStreamer />
      </LayoutStandard>
    )
  },
  {
    path: "/manage-stream-advanced",
    element: (
      <LayoutStandard>
        <AdvancedStream />
      </LayoutStandard>
    )
  },

  // streams (listen)
  {
    path: "/streams/:id",
    element: (
      <LayoutStandard>
        <StreamsListenPage />
      </LayoutStandard>
    )
  },

  // collections
  {
    path: "/collections/new",
    element: (
      <LayoutStandard>
        <CollectionsNewPage />
      </LayoutStandard>
    )
  },
  {
    path: "/collections/:id/detail",
    element: (
      <LayoutStandard>
        <CollectionsDetailPage />
      </LayoutStandard>
    )
  },

  // settings
  {
    path: routes.settings,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <SettingsPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  },
  {
    path: routes.documentation,
    element: (
      <ProtectedRoute>
        <LayoutStandard>
          <DocumentationPage />
        </LayoutStandard>
      </ProtectedRoute>
    )
  }
]);
