import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { io, Socket } from "socket.io-client";
import * as Sentry from "@sentry/react";
//
import env from "../../config/env";
import { isUrlSafe } from "../../utils/formatters";
import { formatElapsedTime } from "../../utils/formatters/time-elapsed";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchMediaDevices,
  setSelectedAudioInput
} from "../../store/settings/slice";
import routes from "../../config/routes";
import { Link } from "react-router-dom";
import { StreamMetadata } from "../../types";
import { getErrorMessage } from "../../utils/error-handlers";

const AudioStreamer: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    loading: audioInputMediaDevicesLoading,
    error: audioInputMediaDevicesError,
    selectedAudioInputId,
    audioInputMediaDevices
  } = useAppSelector((state) => state.settings);

  const streamURLRef = useRef<HTMLInputElement>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [streamTitle, setStreamTitle] = useState<string>(""); // user provided name
  const [streamURL, setStreamURL] = useState<string>(""); // actual full url to stream
  const [streamURLPath, setStreamURLPath] = useState<string>(""); // user provided path (optional)
  const [currentListeners, setCurrentListeners] = useState(0);

  const handleDeviceChange = (event: ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedAudioInput(event.target.value));
  };

  const handleRefreshMediaDevices = () => {
    dispatch(fetchMediaDevices());
  };

  // unmount cleanup
  useEffect(() => {
    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  // show timer
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (startTime) {
        setElapsedTime(formatElapsedTime(startTime));
      } else {
        setElapsedTime("");
      }
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, [startTime]);

  useEffect(() => {
    dispatch(fetchMediaDevices());
  }, []);

  const startStreaming = async () => {
    if (selectedAudioInputId === null) return alert("device not selected");
    if (!streamTitle) return alert("stream name is required");

    // trim & validate vanity
    const trimmedStreamURLPath = streamURLPath.trim();
    if (!isUrlSafe(trimmedStreamURLPath))
      return alert(
        "url path must only contain letters, numbers, dashes, and underscores"
      );
    setStreamURLPath(trimmedStreamURLPath);

    try {
      // on mobile, remove `exact` because there is no selectedAudioInputId
      let audio;

      // value = "default" on mobile
      // problem: "default" can be on desktop, too -- fakkk
      // solution: leave this logic - this works for both desktop/mobile
      if (selectedAudioInputId === "default") {
        audio = true;
      } else {
        audio = { deviceId: { exact: selectedAudioInputId } };
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio
      });

      const recorder = new MediaRecorder(stream); // works on firefox, safari, chrome, ie, and mobile browsers
      recorder.start(100); // Send data every 100ms

      recorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          event.data.arrayBuffer().then((buffer) => {
            newSocket.emit("audio-chunk", buffer);
          });
        }
      };

      setMediaRecorder(recorder);

      const newSocket = io(env.apiHost);

      newSocket.emit("start-stream", {
        title: streamTitle,
        urlPath: trimmedStreamURLPath
      });

      newSocket.on("stream-started", (data: StreamMetadata) => {
        setStreamURL(data.url);
        setStartTime(new Date());
      });

      newSocket.on("stream-error", (message: string) => {
        // todo: log proper error

        console.error("Stream error:", message);
        alert("stream ended");
        stopStreaming();
      });

      newSocket.on("update-listener-count", (currentListeners: number) => {
        setCurrentListeners(currentListeners);
      });

      setSocket(newSocket);

      alert("stream successfully started!");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      alert(getErrorMessage(e));
    }
  };

  const stopStreaming = () => {
    if (mediaRecorder && socket) {
      mediaRecorder.stop();
      socket.emit("stop-stream");
      setStreamURL("");
      setStartTime(null);
    }
  };

  return (
    <div>
      <h1>manage stream</h1>

      <div className="row">
        <div className="col-12 col-md-6">
          <h2>configuration</h2>

          <div className="form-group mb-3">
            <label htmlFor="stream-name">stream name *</label>
            <input
              id="stream-name"
              className="form-control"
              required
              type="text"
              placeholder="e.g., john's relaxing piano stream"
              value={streamTitle}
              onChange={(e) => setStreamTitle(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="url-path">vanity url path (optional)</label>
            <input
              id="url-path"
              className="form-control"
              type="text"
              placeholder="e.g., johns-relaxing-piano-stream"
              aria-description="create a url path which is more descriptive or easy to remember. this can only contain letters, numbers, dashes, and underscores."
              value={streamURLPath}
              onChange={(e) => setStreamURLPath(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="audio-input">audio input source</label>
            <select
              id="audio-input"
              className="form-control"
              value={selectedAudioInputId || ""}
              onChange={handleDeviceChange}
            >
              {audioInputMediaDevices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `microphone ${device.deviceId}`}
                </option>
              ))}
            </select>
            <button
              className="btn btn-outline-primary mt-3"
              onClick={handleRefreshMediaDevices}
              disabled={audioInputMediaDevicesLoading}
            >
              {audioInputMediaDevicesLoading
                ? "loading.."
                : "refresh audio input sources"}
            </button>
            <div aria-label="audio input source error">
              {audioInputMediaDevicesError}
            </div>
          </div>

          <div className="mb-4">
            {streamURL ? (
              <button className="btn btn-danger" onClick={stopStreaming}>
                stop streaming
              </button>
            ) : (
              <button className="btn btn-primary" onClick={startStreaming}>
                go live now
              </button>
            )}
          </div>
          <p className="text-secondary">
            keep this webpage open to keep your stream alive. closing the tab
            will result in the stream ending.
          </p>
        </div>

        {streamURL && (
          <div className="col-12 col-md-6" aria-live="polite">
            <h2>details</h2>

            <div className="form-group mb-3">
              <label htmlFor="stream-url">stream url</label>
              <div className="input-group mb-3">
                <input
                  id="stream-url"
                  className="form-control text-primary"
                  ref={streamURLRef}
                  onClick={() => {
                    window.open(streamURL, "_blank");
                  }}
                  type="text"
                  disabled={!streamURL}
                  value={streamURL}
                  readOnly
                  aria-label="live stream url"
                  aria-description="this is the public url which you can share with others. click this to open the url in a new tab."
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => {
                      if (!streamURLRef.current)
                        return alert("unable to find stream url");

                      const url = streamURLRef.current.value;
                      navigator.clipboard.writeText(url);
                      alert("url copied to clipboard");
                    }}
                  >
                    copy url
                  </button>
                </div>
              </div>
            </div>

            {/* note: add aria-live attribute to announce when this number changes */}

            <div className="form-group mb-3">
              <label htmlFor="current-listeners">current listeners</label>
              <input
                id="current-listeners"
                className="form-control pe-none"
                type="text"
                aria-description="number of active listeners"
                value={currentListeners?.toFixed()}
                aria-live="polite"
                readOnly
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="time-elapsed">time elapsed</label>
              <input
                id="time-elapsed"
                className="form-control pe-none"
                type="text"
                aria-description="when the stream was started"
                value={elapsedTime}
                aria-live="polite"
                readOnly
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <Link to={routes.dashboard}>back</Link>
      </div>
    </div>
  );
};

export default AudioStreamer;
