import { Box } from "@mui/material";
import PricingPageStyled from "./styles";

const pricingOptions = [
  {
    title: "standard plan",
    subTitle: "a toolkit to create, manage and share your live audio",
    features: ["3 hrs live broadcasting per day", "60 file uploads"],
    benefits: [
      "simple live streaming",
      "high-quality recording uploads",
      "embeddable live player"
    ],
    price: 50,
    buttonLabel: "sign up now",
    buttonClasses: "btn btn-secondary btn-lg",
    onButtonClick: () => null,
    isPreferredOption: false,
    isDisabled: true
  },
  {
    title: "pro plan",
    subTitle: "additional features, more control over your audio",
    features: ["12 hrs live broadcasting per day", "120 file uploads"],
    benefits: [
      "includes everything in standard plan",
      "control access to your channel",
      "switch audio quality",
      "live stream url",
      "view listener stats"
    ],
    price: 100,
    buttonLabel: "sign up now",
    buttonClasses: "btn btn-primary btn-lg",
    onButtonClick: () =>
      window.location.assign("https://buy.stripe.com/3cscQM7NB6h20Sc9AH"),
    isPreferredOption: false,
    isDisabled: false
  },
  {
    title: "enterprise plan",
    subTitle: "custom audio solutions and plans for your organization",
    features: ["24/7 live broadcasting", "unlimited upload limits"],
    benefits: [
      "multiple live streams",
      "advanced customizations",
      "enterprise support and onboarding",
      "advanced access control (SSO)"
    ],
    price: 50,
    buttonLabel: "inquire now",
    buttonClasses: "btn btn-outline-primary btn-lg",
    onButtonClick: () => alert("email us at: streamfortheblind@gmail.com"),
    isPreferredOption: false,
    isDisabled: false
  }
];

const PricingPage = () => {
  return (
    <PricingPageStyled>
      <Box component="section" className="pricing-section">
        {pricingOptions.map((p) => (
          <Box
            key={p.title}
            className="pricing-card"
            maxWidth={{ md: "280px" }}
          >
            {/* top */}
            <div className="pb-4">
              <h3>{p.title}</h3>
              <p>{p.subTitle}</p>

              <p className="price">
                ${p.price} <span>per month</span>
              </p>

              <div className="features" role="list">
                {p.features.map((f) => (
                  <p key={f} role="listitem">
                    {f}
                  </p>
                ))}
              </div>

              <ul className="benefits" role="list">
                {p.benefits.map((pf) => (
                  <li key={pf} role="listitem">
                    {pf}
                  </li>
                ))}
              </ul>
            </div>

            {/* bottom */}
            <div className="d-grid gap-2">
              <button
                className={p.buttonClasses}
                disabled={p.isDisabled}
                onClick={p.onButtonClick}
              >
                {p.buttonLabel}
              </button>
            </div>
          </Box>
          // </div>
        ))}

        {/* <div className="pricing-card premium-plus">
          <h3>
            pro plan <span className="popular-badge">popular</span>
          </h3>
          <p>additional features, more control over your audio</p>
          <div className="features">
            <p>
              <strong>12 hrs</strong> live broadcasting per day
            </p>
            <p>
              <strong>120</strong> file uploads
            </p>
          </div>
          <p className="price">
            $100 <span>per month</span>
          </p>
          <button
            className="btn btn-primary btn-lg"
            onClick={() =>
              window.location.assign(
                "https://buy.stripe.com/3cscQM7NB6h20Sc9AH"
              )
            }
          >
            sign up now
          </button>
          <ul className="benefits">
            <li>includes everything in standard plan</li>
            <li>control access to your channel</li>
            <li>switch audio quality</li>
            <li>live stream url</li>
            <li>advanced stats</li>
          </ul>
        </div>

        <div className="pricing-card enterprise">
          <h3>enterprise</h3>
          <p>custom audio solutions and plans for your organization</p>
          <button
            className="btn btn-outline-primary btn-lg"
            onClick={() => alert("email us at: streamfortheblind@gmail.com")}
          >
            inquire now
          </button>
          <ul className="benefits">
            <li>advanced customizations</li>
            <li>multiple channels</li>
            <li>enterprise support and onboarding</li>
            <li>monetization</li>
            <li>advanced access control (SSO)</li>
          </ul>
        </div> */}
      </Box>
    </PricingPageStyled>
  );
};

export default PricingPage;
