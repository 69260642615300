import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
//
import routes from "../../../config/routes";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import RecordingForm, {
  RecordingFormData
} from "../../../components/forms/recording";
import { useAppSelector } from "../../../store/hooks";

const UploadPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: availableCollections } = useAppSelector(
    (state) => state.myCollections
  );
  const [loading, setLoading] = useState(false);
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [artworkFile, setArtworkFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<RecordingFormData>({
    title: "",
    description: "",
    is_public: true,
    artwork_alt_text: "",
    //
    collections: []
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    calculatedValue?: any
  ) => {
    const { name, value, type } = e.target;
    // @ts-ignore
    setFormData((prev) => ({
      ...prev,
      [name]: calculatedValue
        ? calculatedValue
        : type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : value
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setAudioFile(file);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Safely access the first file
    if (file) {
      setArtworkFile(file);
    } else {
      setArtworkFile(null);
    }
  };

  const handleUpload = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.title.trim()) {
      alert("please enter a title");
      return;
    }

    if (!audioFile) {
      alert("please select an audio file to upload");
      return;
    }

    try {
      setLoading(true);

      // Create FormData object to send files and form data
      const formDataToSend = new FormData();

      // Append form fields
      formDataToSend.append("title", formData.title);
      if (formData.description) {
        formDataToSend.append("description", formData.description);
      }
      formDataToSend.append("is_public", String(formData.is_public));
      formDataToSend.append(
        "artwork_alt_text",
        formData.artwork_alt_text || ""
      );
      formDataToSend.append(
        "collections",
        JSON.stringify(formData.collections)
      );

      // Append files
      formDataToSend.append("audio", audioFile);

      if (artworkFile) {
        formDataToSend.append("artwork", artworkFile);
      }

      // Send the request
      await apiClient.post("/recordings", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      alert("recording uploaded successfully!");
      navigate(routes.dashboard);
    } catch (error) {
      alert(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <h1>upload recording</h1>

      <RecordingForm
        onSubmit={handleUpload}
        formData={formData}
        onInputChange={handleInputChange}
        onFileChange={handleFileChange}
        onImageChange={handleImageChange}
        artworkFile={artworkFile}
        loading={loading}
        formButtonSubmitLabel="Upload"
        artworkUrl={null}
        availableCollections={availableCollections}
      />
    </main>
  );
};

export default UploadPage;
