const routes = {
  home: "/",
  dashboard: "/dashboard",
  discover: "/discover",

  // auth
  login: "/login",
  register: "/register",
  pricing: "/pricing",

  // settings
  settings: "/settings",
  documentation: "/documentation",

  // manage-stream
  stream: "/manage-stream",
  streamAdvanced: "/manage-stream-advanced",

  // recordings
  recordings: "/recordings",
  recordingsNew: "/recordings/new",

  // collections
  collections: "/collections",
  collectionsNew: "/collections/new"
};

export default routes;
