import { ReactElement } from "react";
//
import Footer from "../../footer";
import Nav from "../../nav";

const LayoutStandard = ({ children }: { children: ReactElement }) => {
  return (
    <>
      <Nav />
      <div className="container">
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default LayoutStandard;
