import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import { Tables } from "../../types/database.types";
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";

interface AllRecordingsState {
  loading: boolean;
  error?: string;
  data: Tables<"recordings">[];
}

const initialState: AllRecordingsState = {
  loading: false,
  data: []
};

export const allRecordingsSlice = createSlice({
  name: "allRecordings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRecordings.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAllRecordings.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllRecordings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchAllRecordings = createAsyncThunk<Tables<"recordings">[]>(
  "allRecordings/fetchAllRecordings",
  async () => {
    try {
      const { data } = await apiClient.get("/recordings");
      return data;
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }
);

export default allRecordingsSlice.reducer;
