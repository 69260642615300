import { Link } from "react-router-dom";
//
import routes from "../../config/routes.ts";
import { useAuth } from "../auth-provider/index.tsx";
import { Box, Stack } from "@mui/material";

const Nav = () => {
  const { session } = useAuth();

  return (
    <Box
      component="nav"
      aria-label="main navigation"
      sx={{ backgroundColor: "#e6f3ff", paddingBottom: 1 }}
      mb={{ xs: "16px" }}
    >
      <div className="container">
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {/* left */}
          <Box sx={{ width: { xs: "100%" } }}>
            {/* sight identity */}
            <div>
              <Link to={routes.home} aria-label="return to home page">
                <h1 className="mb-0">stream for the blind</h1>
              </Link>
              <div className="pe-4">
                the world's most accessible live stream management system
              </div>
            </div>
          </Box>

          {/* right */}
          <Box
            display="flex"
            alignItems="center"
            width={{ xs: "100%", sm: "50%" }}
            justifyContent={{ xs: "flex-start", sm: "flex-end" }}
            sx={{
              gap: 2,
              py: {
                xs: 1
              }
            }}
          >
            {session ? (
              <>
                <Link to={routes.discover}>discover</Link>
                <Link to={routes.dashboard}>dashboard</Link>
                <Link to={routes.settings}>settings</Link>
              </>
            ) : (
              <>
                <Link to={routes.discover}>discover</Link>
                <Link to={routes.pricing}>pricing</Link>
                <Link to={routes.login}>login</Link>
              </>
            )}
          </Box>
        </Stack>
      </div>
    </Box>
  );
};

export default Nav;
