import { Link } from "react-router-dom";
import routes from "../../config/routes";

const DocumentationPage = () => (
  <main>
    <section id="overview" className="mb-4">
      <h1>documentation</h1>
      <p>
        <i>last updated: 2024-11-19</i>
      </p>
      <p>
        these are the available endpoints and the request and response details.
      </p>
      <p>
        note: you must pass a valid api key within the header object's{" "}
        <code>x-api-key</code> attribute
      </p>
    </section>

    <section id="streams" className="mb-4">
      <h2>streams</h2>
      <div role="list" className="my-3">
        <div role="listitem">
          <span className="text-primary pe-3">
            <strong>GET</strong>
          </span>
          <span>https://api.streamfortheblind.com/streams</span>
          <p className="ps-4 text-secondary">returns an array of all streams</p>
        </div>

        {/* <div role="listitem">
          <span className="text-success pe-3">POST</span>
          <span>https://api.streamfortheblind.com/streams</span>
          <p className="ps-4">creates a new stream</p>
        </div>

        <div role="listitem">
          <span className="text-danger pe-3">DELETE</span>
          <span>https://api.streamfortheblind.com/streams/:id</span>
        </div> */}
      </div>
    </section>

    <section id="recordings" className="mb-4">
      <h2>recordings</h2>
      <div role="list" className="my-3">
        <div role="listitem">
          <span className="text-primary pe-3">
            <strong>GET</strong>
          </span>
          <span>https://api.streamfortheblind.com/recordings/mine</span>
          <p className="ps-4 text-secondary">
            returns an array of all recordings owned by user
          </p>
        </div>

        <div role="listitem">
          <span className="text-success pe-3">
            <strong>POST</strong>
          </span>
          <span>https://api.streamfortheblind.com/recordings</span>
          <span className="badge text-bg-warning ms-2">
            in active development
          </span>
          <p className="text-secondary ps-4">creates a new recording</p>
        </div>

        <div role="listitem">
          <span className="text-danger pe-3">
            <strong>DELETE</strong>
          </span>
          <span>https://api.streamfortheblind.com/recordings/:id</span>
          <p className="text-secondary ps-4">delete a recording by its id</p>
        </div>
      </div>

      <section id="support" className="mt-4 mb-4">
        <h2>support</h2>
        <p>
          don't see an endpoint? check back later, we are always updating our
          system.
        </p>
        <p>
          have a request or want to submit a bug?{" "}
          <a href="mailto:streamfortheblind@gmail.com">send us an email</a>
        </p>
      </section>
    </section>

    <div className="mt-4">
      <Link to={routes.dashboard}>back</Link>
    </div>
  </main>
);

export default DocumentationPage;
