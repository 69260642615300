import { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { Box } from "@mui/material";
//
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import routes from "../../../config/routes";
import { Tables } from "../../../types/database.types";
import fallbackImage from "../../../assets/images/generic-artwork-image.jpg";
import LiveBadge from "../../../components/live-badge";

const StreamsListenPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [stream, setStream] = useState<Tables<"streams"> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // redirect if no stream is found
  useEffect(() => {
    if (!id) {
      navigate(routes.dashboard);
    }
  }, [id, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      // reminder: this returns a STRING
      const external = searchParams.get("external");
      if (external === undefined) {
        alert("invalid url, please try another one");
        navigate(routes.discover);
      }

      try {
        setLoading(true);

        if (external === "true") {
          const { data } = await apiClient.get(`/external-streams/${id}`);
          setStream(data);
        } else {
          const { data } = await apiClient.get(`/streams/${id}`);
          setStream(data);
        }
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        setError(errorMessage);
        alert(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>loading...</div>;
  if (!stream) return <Navigate to={routes.discover} />;

  return (
    <main>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>stream</h1>
      </div>

      <Box
        component="section"
        id="playback"
        sx={{
          backgroundColor: "#f7f7f7",
          paddingTop: {
            xs: 4
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          paddingBottom: {
            xs: 2
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          textAlign: "center",
          borderRadius: 4
        }}
      >
        <Box>
          <LiveBadge />
        </Box>
        <Box
          component="img"
          src={stream.artwork_url || fallbackImage}
          alt={`image for stream ${stream.title}`}
          sx={{
            height: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            width: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            objectFit: "cover", // prevent image from getting distorted/losing aspect ratio
            borderRadius: 2,
            marginTop: 2,
            marginBottom: 2,
            border: "1px solid grey"
          }}
        />

        <h1>{stream.title}</h1>

        <div>
          <audio
            autoPlay
            controls
            style={{
              width: "100%",
              maxWidth: "800px",
              padding: "16px"
            }}
          >
            <source src={stream.url || ""} type={`audio/mp3`} />
            your browser does not support the audio element.
          </audio>
        </div>

        <p>{stream.description}</p>
      </Box>

      <div aria-label="error">{error}</div>
      <div aria-label="is loading">{loading}</div>

      <div className="mt-4">
        <Link to={routes.discover}>back</Link>
      </div>
    </main>
  );
};

export default StreamsListenPage;
