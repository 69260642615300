import { StrictMode } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
//
import { store } from "./store";
import { AuthProvider } from "./components/auth-provider";
import GlobalStyles from "./styles";
import App from "./components/_app";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

Sentry.init({
  dsn: "https://47924f49469c13f323d35562bf0f8c56@o4507913113829376.ingest.us.sentry.io/4508297732358144",
  integrations: []
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <GlobalStyles />
        <App />
      </Provider>
    </AuthProvider>
  </StrictMode>
);
