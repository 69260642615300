import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
//
import routes from "../../../config/routes";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import { Tables } from "../../../types/database.types";
import { useAppDispatch } from "../../../store/hooks";
import { fetchMyCollections } from "../../../store/my-collections/slice";
import { Box } from "@mui/material";

type CollectionData = Tables<"collections">;

const CollectionsEditPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState<CollectionData | null>(null);
  const [artworkFile, setArtworkFile] = useState<File | null>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    // @ts-ignore
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setArtworkFile(file || null);
  };

  const clearArtworkUrl = () => {
    // @ts-ignore
    setFormData((old) => ({
      ...old,
      artwork_url: ""
    }));
  };

  const handleDelete = async () => {
    if (!formData) return;

    const shouldDelete = confirm(
      `are you sure you want to delete the collection: ${formData.title}`
    );

    if (shouldDelete) {
      try {
        setError("");
        setLoading(true);
        await apiClient.delete(`/collections/${formData.id}`);
        dispatch(fetchMyCollections());
        alert("successfully deleted collection");
        navigate(routes.dashboard);
      } catch (e) {
        alert(getErrorMessage(e));
        setError(getErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!formData) return alert("no collection found");

    try {
      setLoading(true);

      // Create FormData for file uploads if files were changed
      const formDataToSend = new FormData();

      // Append metadata
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description || "");
      formDataToSend.append("is_public", String(formData.is_public));
      formDataToSend.append("artwork_url", formData.artwork_url || "");
      formDataToSend.append(
        "artwork_alt_text",
        formData.artwork_alt_text || ""
      );

      if (artworkFile) {
        formDataToSend.append("artwork", artworkFile);
      }

      console.log("formData", formData);

      await apiClient.put(`/collections/${formData.id}`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      dispatch(fetchMyCollections());
      alert("successfully saved collection");
      navigate(routes.dashboard);
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  // Fetch collection data
  useEffect(() => {
    const fetchCollections = async () => {
      if (!id) {
        navigate(routes.dashboard);
        return;
      }

      try {
        const { data } = await apiClient.get<Tables<"collections">>(
          `/collections/${id}`
        );

        setFormData(data);
      } catch (e) {
        alert(getErrorMessage(e));
        navigate(routes.dashboard);
      } finally {
        setLoading(false);
      }
    };

    fetchCollections();
  }, [id]);

  if (loading) return <div>loading...</div>;
  if (!formData) return <Navigate to={routes.dashboard} />;

  return (
    <main>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>edit collection</h2>
        <button className="btn btn-danger" onClick={handleDelete}>
          delete
        </button>
      </div>

      <form onSubmit={handleFormSubmit} className="form-container">
        <div className="form-group mb-3">
          <label htmlFor="title">title</label>
          <input
            id="title"
            name="title"
            className="form-control"
            type="text"
            value={formData.title}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="description">description</label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            value={formData.description || ""}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <div className="form-check mb-3">
          <input
            id="is_public"
            name="is_public"
            type="checkbox"
            className="form-check-input"
            checked={formData.is_public}
            onChange={handleInputChange}
          />
          <label className="form-check-label" htmlFor="is_public">
            make public?
          </label>
        </div>

        <Box sx={{ flex: 1, mt: { xs: 2, sm: 0 } }}>
          <div className="form-group mb-2">
            <label htmlFor="artworkFile">artwork image</label>
            <input
              id="artworkFile"
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>

          {artworkFile ? (
            <div>
              <img
                src={URL.createObjectURL(artworkFile)}
                style={{ height: 200, objectFit: "cover", marginTop: 8 }}
                alt={`artwork image for collection: ${artworkFile.name}`}
              />

              <br />

              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() =>
                  handleImageChange({ target: { files: null } } as any)
                }
              >
                remove artwork image
              </button>
            </div>
          ) : formData.artwork_url ? (
            <div>
              <img
                src={formData.artwork_url}
                alt={`artwork image for collection: ${formData.artwork_url}`}
                style={{ height: 200, objectFit: "cover", marginTop: 8 }}
              />
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => {
                  clearArtworkUrl!();
                  handleImageChange({ target: { files: null } } as any);
                }}
              >
                remove artwork image
              </button>
            </div>
          ) : null}

          <div className="form-group mb-2">
            <label htmlFor="artwork_alt_text">artwork alt text</label>
            <input
              id="artwork_alt_text"
              name="artwork_alt_text"
              className="form-control"
              type="text"
              value={formData.artwork_alt_text || ""}
              onChange={handleInputChange}
            />
          </div>
        </Box>

        <button className="btn btn-primary" type="submit" disabled={loading}>
          {loading ? "loading..." : "save"}
        </button>

        <div>{error}</div>
      </form>
      <Link to={routes.dashboard} className="d-block mt-4">
        back
      </Link>
    </main>
  );
};

export default CollectionsEditPage;
