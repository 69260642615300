import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
//
import routes from "../../../config/routes";
import { Tables } from "../../../types/database.types";
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import RecordingForm, {
  RecordingFormData
} from "../../../components/forms/recording";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchMyRecordings } from "../../../store/my-recordings/slice";

const RecordingsEditPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: availableCollections } = useAppSelector(
    (state) => state.myCollections
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [artworkFile, setArtworkFile] = useState<File | null>(null);
  const [formData, setFormData] = useState<RecordingFormData | null>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    calculatedValue?: any
  ) => {
    const { name, value, type } = e.target;
    // @ts-ignore
    setFormData((prev) => ({
      ...prev,
      [name]: calculatedValue
        ? calculatedValue
        : type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : value
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setAudioFile(file);
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setArtworkFile(file || null);
  };

  const handleDelete = async () => {
    if (!formData) return;

    const shouldDelete = confirm(
      `are you sure you want to delete the recording: ${formData.title}`
    );

    if (shouldDelete) {
      try {
        setLoadingDelete(true);
        await apiClient.delete(`/recordings/${id}`);
        dispatch(fetchMyRecordings());
        alert("successfully deleted recording");
        navigate(routes.dashboard);
      } catch (e) {
        alert(getErrorMessage(e));
      } finally {
        setLoadingDelete(false);
      }
    }
  };

  const clearArtworkUrl = () => {
    // @ts-ignore
    setFormData((old) => ({
      ...old,
      artwork_url: ""
    }));
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!formData) return alert("no recording found");

    try {
      setLoading(true);

      // Create FormData for file uploads if files were changed
      const formDataToSend = new FormData();

      // Append metadata
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description || "");
      formDataToSend.append("is_public", String(formData.is_public));
      formDataToSend.append("artwork_url", formData.artwork_url || "");
      formDataToSend.append(
        "artwork_alt_text",
        formData.artwork_alt_text || ""
      );
      formDataToSend.append(
        "collections",
        JSON.stringify(formData.collections)
      );

      // Append new files if they exist
      if (audioFile) {
        formDataToSend.append("audio", audioFile);
      }

      if (artworkFile) {
        formDataToSend.append("artwork", artworkFile);
      }

      await apiClient.put(`/recordings/${formData.id}`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      dispatch(fetchMyRecordings());
      alert("successfully saved recording");
      navigate(routes.dashboard);
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  // Fetch recording data
  useEffect(() => {
    const fetchRecording = async () => {
      if (!id) {
        navigate(routes.dashboard);
        return;
      }

      try {
        const { data } = await apiClient.get<Tables<"recordings">>(
          `/recordings/${id}`
        );

        // @ts-ignore
        setFormData(data);
      } catch (e) {
        alert(getErrorMessage(e));
        navigate(routes.dashboard);
      } finally {
        setLoading(false);
      }
    };

    fetchRecording();
  }, [id]);

  if (loading) return <div>loading...</div>;
  if (!formData) return <Navigate to={routes.dashboard} />;

  return (
    <main>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>edit recording</h1>
        <button
          onClick={handleDelete}
          className="btn btn-danger mx-4"
          disabled={loadingDelete}
        >
          {loadingDelete ? "loading..." : "delete"}
        </button>
      </div>

      <section id="playback"></section>

      <RecordingForm
        onSubmit={handleFormSubmit}
        formData={formData}
        onInputChange={handleInputChange}
        onFileChange={handleFileChange}
        onImageChange={handleImageChange}
        artworkFile={artworkFile}
        loading={loading}
        filename={formData.filename}
        formButtonSubmitLabel="save"
        artworkUrl={formData.artwork_url || null}
        clearArtworkUrl={clearArtworkUrl}
        availableCollections={availableCollections}
      />
    </main>
  );
};

export default RecordingsEditPage;
