import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";
import { StreamMetadata } from "../../types";

interface ExternalStreamsState {
  loading: boolean;
  error?: string;
  data: StreamMetadata[];
}

const initialState: ExternalStreamsState = {
  loading: false,
  data: []
};

export const externalStreamsSlice = createSlice({
  name: "externalStreams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExternalStreams.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchExternalStreams.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchExternalStreams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchExternalStreams = createAsyncThunk<StreamMetadata[]>(
  "externalStreams/fetchExternalStreams",
  async () => {
    try {
      const { data } = await apiClient.get("/external-streams");
      return data;
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }
);

export default externalStreamsSlice.reducer;
