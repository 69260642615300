import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
//
import apiClient from "../../../services/api";
import { getErrorMessage } from "../../../utils/error-handlers";
import routes from "../../../config/routes";
import { Tables } from "../../../types/database.types";
import fallbackImage from "../../../assets/images/generic-artwork-image.jpg";

const RecordingsListenPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [recording, setRecording] = useState<Tables<"recordings"> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // redirect if no recording is found
  useEffect(() => {
    if (!id) {
      navigate(routes.dashboard);
    }
  }, [id, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await apiClient.get(`/recordings/${id}`);
        setRecording(data);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        setError(errorMessage);
        alert(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>loading...</div>;
  if (!recording) return <Navigate to={routes.discover} />;

  return (
    <main>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>recording</h1>
      </div>

      <Box
        component="section"
        id="playback"
        sx={{
          backgroundColor: "#f7f7f7",
          paddingTop: {
            xs: 4
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          paddingBottom: {
            xs: 2
            // sm: 4,
            // md: 6,
            // lg: 8
          },
          textAlign: "center",
          borderRadius: 4
        }}
      >
        <Box
          component="img"
          src={recording.artwork_url || fallbackImage}
          alt={`image for recording ${recording.title}`}
          sx={{
            height: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            width: {
              xs: 220,
              sm: 240,
              md: 260,
              lg: 280,
              xl: 300
            },
            objectFit: "cover", // prevent image from getting distorted/losing aspect ratio
            borderRadius: 2,
            marginTop: 2,
            marginBottom: 2,
            border: "1px solid grey"
          }}
        />

        <h1>{recording.title}</h1>

        <div>
          <audio
            autoPlay
            controls
            style={{
              width: "100%",
              maxWidth: "800px",
              padding: "16px"
            }}
          >
            <source
              src={recording.url}
              type={`audio/${recording.filename.split(".")[1]}`} // get the extension from original filename
            />
            your browser does not support the audio element.
          </audio>
        </div>

        <p>{recording.description}</p>
      </Box>

      <div aria-label="error">{error}</div>
      <div aria-label="is loading">{loading}</div>

      <div className="mt-4">
        <Link to={routes.discover}>back</Link>
      </div>
    </main>
  );
};

export default RecordingsListenPage;
