import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";
import { StreamMetadata } from "../../types";

interface MyStreamsState {
  loading: boolean;
  error?: string;
  data: StreamMetadata[];
}

const initialState: MyStreamsState = {
  loading: false,
  data: []
};

export const myStreamsSlice = createSlice({
  name: "myStreams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyStreams.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchMyStreams.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchMyStreams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchMyStreams = createAsyncThunk<StreamMetadata[]>(
  "myStreams/fetchMyStreams",
  async () => {
    try {
      const { data } = await apiClient.get("/streams/mine");
      return data;
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }
);

export default myStreamsSlice.reducer;
