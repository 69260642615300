import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//
import routes from "../../config/routes";
import Login from "./Login";
import { useAuth } from "../../components/auth-provider";

const LoginPage = () => {
  const { session } = useAuth();
  const navigate = useNavigate();

  // redirect user if they are already logged in
  useEffect(() => {
    if (session) {
      navigate(routes.dashboard);
    }
  }, [session, navigate]);

  return (
    <main>
      <Login />
    </main>
  );
};

export default LoginPage;
