import { configureStore } from "@reduxjs/toolkit";
//
import { myStreamsSlice } from "./my-streams/slice";
import { settingsSlice } from "./settings/slice";
import { myCollectionsSlice } from "./my-collections/slice";
import { myRecordingsSlice } from "./my-recordings/slice";
import { allStreamsSlice } from "./all-streams/slice";
import { allRecordingsSlice } from "./all-recordings/slice";
import { externalStreamsSlice } from "./external-streams/slice";

export const store = configureStore({
  reducer: {
    myStreams: myStreamsSlice.reducer,
    myCollections: myCollectionsSlice.reducer,
    myRecordings: myRecordingsSlice.reducer,
    //
    allStreams: allStreamsSlice.reducer,
    allRecordings: allRecordingsSlice.reducer,
    //
    externalStreams: externalStreamsSlice.reducer,
    //
    settings: settingsSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
