import { useState, useRef, useEffect } from "react";
import { useAppSelector } from "../../store/hooks";
//

const RECORDING_DURATION = 4;

const MicrophoneTester = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [countdown, setCountdown] = useState(RECORDING_DURATION);
  const mediaRecorderRef = useRef<any>(null);
  const chunksRef = useRef<any>([]);

  const { selectedAudioInputId } = useAppSelector((state) => state.settings);

  const startRecording = async () => {
    try {
      if (selectedAudioInputId === null) return alert("device not selected");

      // on mobile, remove `exact` because there is no selectedAudioInputId
      let audio;

      // value = "default" on mobile
      // problem: "default" can be on desktop, too -- fakkk
      // solution: leave this logic - this works for both desktop/mobile
      if (selectedAudioInputId === "default") {
        audio = true;
      } else {
        audio = { deviceId: { exact: selectedAudioInputId } };
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio
      });

      mediaRecorderRef.current = new MediaRecorder(stream);
      chunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (e: any) => {
        chunksRef.current.push(e.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/webm" });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
        setIsRecording(false);
        setCountdown(4);

        // Stop all tracks to release the microphone
        stream.getTracks().forEach((track) => track.stop());
      };

      setIsRecording(true);
      mediaRecorderRef.current.start();

      // Start countdown
      let timeLeft = RECORDING_DURATION;
      const countdownInterval = setInterval(() => {
        timeLeft -= 1;
        setCountdown(timeLeft);

        if (timeLeft === 0) {
          clearInterval(countdownInterval);
          mediaRecorderRef.current.stop();
        }
      }, 1000);
    } catch (err) {
      alert("please allow microphone access to test your audio");
      console.error("Error accessing microphone:", err);
    }
  };

  useEffect(() => {
    const playRecording = () => {
      if (!audioUrl) return alert("no audio url found, please try again later");
      const audio = new Audio(audioUrl);
      audio.play();
      setIsPlaying(true);
      setTimeout(() => {
        setIsPlaying(false);
      }, RECORDING_DURATION * 1000);
    };

    if (audioUrl) {
      playRecording();
      setAudioUrl(null);
    }
  }, [audioUrl]);

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4 items-start">
        <button
          onClick={startRecording}
          disabled={isRecording || isPlaying}
          className="btn btn-outline-primary w-40 mt-2"
        >
          {isRecording ? (
            <>
              <div
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-label="microphone testing in progress"
              />
              recording... {countdown}s
            </>
          ) : isPlaying ? (
            <>
              <div
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-label="playing test audio"
              />
              playing...
            </>
          ) : (
            "press to record sample clip"
          )}
        </button>
      </div>
    </div>
  );
};

export default MicrophoneTester;
