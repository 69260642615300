import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//
import supabase from "../../services/supabase";
import routes from "../../config/routes";
import { getErrorMessage } from "../../utils/error-handlers";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLoginFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);

      const { error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) {
        throw error;
      }

      navigate(routes.dashboard);
    } catch (e) {
      alert(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h1>login</h1>

      <form className="my-4" onSubmit={handleLoginFormSubmit}>
        <div className="form-group mb-3 text-start">
          <label htmlFor="email">email</label>
          <input
            id="email"
            className="form-control"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group mb-4 text-start">
          <label htmlFor="password">password</label>
          <input
            id="password"
            className="form-control"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="d-grid mb-3">
          {loading ? (
            <div>loading...</div>
          ) : (
            <button className="btn btn-primary" type="submit">
              login
            </button>
          )}
        </div>
      </form>

      <Link to={routes.register}>don't have an account?</Link>
      <br />
      <Link to={routes.home}>back</Link>
    </div>
  );
};

export default Login;
