import { intervalToDuration } from "date-fns";

export function formatElapsedTime(startTime: Date) {
  const now = new Date();

  // Get duration object using date-fns
  const duration = intervalToDuration({ start: startTime, end: now });

  // Extract values and format as dd:hh:mm:ss
  const days = duration.days || 0;
  const hours = duration.hours || 0;
  const minutes = duration.minutes || 0;
  const seconds = duration.seconds || 0;

  const formatNumber = (num: number) => String(num).padStart(2, "0");
  return `${formatNumber(days)}:${formatNumber(hours)}:${formatNumber(
    minutes
  )}:${formatNumber(seconds)}`;
}
