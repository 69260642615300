import { Box } from "@mui/material";

type LiveBadgeProps = {
  color?: string;
};

const LiveBadge = ({ color = "red" }: LiveBadgeProps) => (
  <Box
    sx={{
      backgroundColor: color,
      borderRadius: 4,
      display: "inline-flex",
      alignItems: "center",
      paddingLeft: 1.25,
      paddingRight: 1.25
    }}
  >
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "100px",
        width: 10,
        height: 10,
        marginRight: 1
      }}
    />
    <Box sx={{ color: "white", fontWeight: 600 }}>LIVE</Box>
  </Box>
);

export default LiveBadge;
