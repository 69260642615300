import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";
import { StreamMetadata } from "../../types";

interface AllStreamsState {
  loading: boolean;
  error?: string;
  data: StreamMetadata[];
}

const initialState: AllStreamsState = {
  loading: false,
  data: []
};

export const allStreamsSlice = createSlice({
  name: "allStreams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStreams.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchAllStreams.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllStreams.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchAllStreams = createAsyncThunk<StreamMetadata[]>(
  "allStreams/fetchAllStreams",
  async () => {
    try {
      const { data } = await apiClient.get("/streams");
      return data;
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }
);

export default allStreamsSlice.reducer;
