import { Link } from "react-router-dom";
import routes from "../../config/routes";

const email = "streamfortheblind@gmail.com";

const RegisterPage = () => (
  <div>
    <h1>register</h1>
    <p>
      to inquire about a new account, please submit an inquiry to the following
      email address:
    </p>
    <a href={`mailto:${email}`}>{email}</a>

    <br />
    <br />

    <Link to={routes.login}>back</Link>
  </div>
);

export default RegisterPage;
