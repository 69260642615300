import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
//
import { router } from "../../components/router";
import { useAppDispatch } from "../../store/hooks";
import { fetchAllRecordings } from "../../store/all-recordings/slice";
import { fetchAllStreams } from "../../store/all-streams/slice";
import { fetchExternalStreams } from "../../store/external-streams/slice";
import { fetchMediaDevices } from "../../store/settings/slice";

const App = () => {
  const dispatch = useAppDispatch();

  // init logic (e.g., fetch data)
  useEffect(() => {
    dispatch(fetchAllStreams());
    dispatch(fetchAllRecordings());
    dispatch(fetchExternalStreams());
    dispatch(fetchMediaDevices());
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
