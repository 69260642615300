import { Link } from "react-router-dom";

const HomePage2 = () => {
  return (
    <main>
      <section aria-label="Platform benefits" id="benefits" className="mb-4">
        <h2>why choose us?</h2>
        <ul>
          <li>
            live stream management built specfically for those with blindness
            and visual impairement
          </li>
          <li>stream live straight from your browser on desktop or mobile</li>
          <li>
            grow your community following by joining our network of likeminded
            people
          </li>

          <li>
            developer-friendly apis that enable seamless integration with other
            apps
          </li>
          <li>
            performance, reliability, and security that exceed industry
            standards
          </li>
        </ul>
      </section>

      <section aria-label="Subscription plans" id="plans" className="mb-4">
        <h2>plans for everyone</h2>
        <p>
          accessible pricing for every need. whether you're a hobbyist or a
          large organization, we have a plan for you.
        </p>
        <ul>
          <li>
            <strong>basic plan:</strong> $50/month - essential features for
            small streams just getting started.
          </li>
          <li>
            <strong>pro plan:</strong> $100/month - higher limits for streams
            with extended broadcast periods.
          </li>
          <li>
            <strong>enterprise:</strong> <i>inquire for custom price</i> -
            tailored solution to fit your organization's specific needs.
          </li>
        </ul>

        <div>
          <div>
            learn more at our <Link to="/pricing">pricing page</Link> or{" "}
            <button
              className="btn btn-primary btn-sm mx-1"
              onClick={() =>
                window.location.assign(
                  "https://buy.stripe.com/3cscQM7NB6h20Sc9AH"
                )
              }
            >
              sign up now
            </button>
          </div>
        </div>
      </section>

      <section
        aria-label="Accessibility commitment"
        id="accessibility"
        className="mb-4"
      >
        <h2>our commitment to accessibility</h2>
        <p>
          we believe everyone deserves a voice. our platform is designed with
          accessibility at its core, making it easy for anyone, regardless of
          ability, to create, manage, and share content.
        </p>
      </section>
    </main>
  );
};

export default HomePage2;
