import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//
import { Tables } from "../../types/database.types";
import apiClient from "../../services/api";
import { getErrorMessage } from "../../utils/error-handlers";

interface MyRecordingsState {
  loading: boolean;
  error?: string;
  data: Tables<"recordings">[];
}

const initialState: MyRecordingsState = {
  loading: false,
  data: []
};

export const myRecordingsSlice = createSlice({
  name: "myRecordings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyRecordings.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchMyRecordings.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchMyRecordings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const fetchMyRecordings = createAsyncThunk<Tables<"recordings">[]>(
  "myRecordings/fetchMyRecordings",
  async () => {
    try {
      const { data } = await apiClient.get("/recordings/mine");
      return data;
    } catch (e) {
      throw new Error(getErrorMessage(e));
    }
  }
);

export default myRecordingsSlice.reducer;
