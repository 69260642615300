import React, { useState, useEffect } from "react";
import { io, Socket } from "socket.io-client";
//
import env from "../../config/env";
import { StreamMetadata } from "../../types";

const AdvancedStream: React.FC = () => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [streamTitle, setStreamTitle] = useState<string>("");
  const [streamURL, setStreamURL] = useState<string>("");
  const [currentListeners, setCurrentListeners] = useState(0);

  useEffect(() => {
    const newSocket = io(env.apiHost);
    setSocket(newSocket);

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream); // works on firefox, safari, chrome, IE
        setMediaRecorder(recorder);
      })
      .catch((err) => {
        console.error("Error accessing microphone:", err);
      });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const startStreaming = () => {
    if (mediaRecorder && socket) {
      const _streamTitle = prompt("enter a stream title:");
      if (!_streamTitle) {
        alert("stream title is required.");
        return;
      }
      setStreamTitle(_streamTitle);

      const _streamURLPath = prompt(
        "(optional) enter a stream URL path (cannot contain spaces or special characters except for dashes and underscores)"
      );

      socket.emit("start-stream-advanced", {
        title: _streamTitle,
        urlPath: _streamURLPath
      });

      socket.on("stream-advanced-started", (data: StreamMetadata) => {
        setStreamURL(data.url);
      });

      socket.on("stream-advanced-error", (message: string) => {
        console.error("Stream error:", message);
        alert(message);
        stopStreaming();
      });

      socket.on("update-listener-count", (currentListeners: number) => {
        setCurrentListeners(currentListeners);
      });

      mediaRecorder.start(100); // Send data every 100ms

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        if (event.data && event.data.size > 0) {
          event.data.arrayBuffer().then((buffer) => {
            socket.emit("audio-chunk", buffer);
          });
        }
      };
    }
  };

  const stopStreaming = () => {
    if (mediaRecorder && socket) {
      mediaRecorder.stop();
      socket.emit("stop-stream-advanced");
      setStreamTitle("");
      setStreamURL("");
    }
  };

  return (
    <div>
      <h1>advanced stream</h1>

      <div className="mb-4">
        {streamURL ? (
          <button
            className="btn btn-danger"
            onClick={stopStreaming}
            disabled={!streamTitle}
          >
            stop streaming
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={startStreaming}
            disabled={!!streamTitle}
          >
            go live now (advanced)
          </button>
        )}
      </div>

      {streamURL && (
        <div>
          <p>
            your stream URL:{" "}
            <a href={streamURL} target="_blank">
              {streamURL}
            </a>
          </p>

          {/* note: add aria-live attribute to announce when this number changes */}
          <p aria-live="polite">current listeners: {currentListeners}</p>

          <p style={{ fontStyle: "italic" }}>
            keep this webpage open to keep your stream alive. closing the tab
            will result in the stream ending.
          </p>
        </div>
      )}
    </div>
  );
};

export default AdvancedStream;
